<template>
  <!-- <div> -->
  <keep-alive>
    <manage-device v-if="displayMode==1" @display-change="displayChange"></manage-device>
    <manage-person v-else-if="displayMode==2" @display-change="displayChange"></manage-person>
    <!-- <manage-object v-else-if="displayMode==3" @display-change="displayChange"></manage-object> -->
    <manage-complex v-else @display-change="displayChange"></manage-complex>
    <!-- <manage-vehicle v-else @display-change="displayChange"></manage-vehicle> -->
  </keep-alive>
  <!-- </div> -->
</template>

<script>
  // const { ManageVehicle, ManageDevice } = require('@/router/components' + process.env.VUE_APP_VRC + '.js')
  const {
    ManagePerson,
    ManageDevice,
    ManageObject,
    ManageComplex
  } = require('@/router/components.js')
  export default {
    name: 'manageGroup',
    data() {
      return {
        displayMode: 4
      }
    },
    methods: {
      displayChange(val) {
        this.displayMode = val
      }
    },
    components: {
      ManageDevice,
      ManagePerson,
      ManageObject,
      ManageComplex
      // ManageVehicle
    }
  }

</script>

<style lang="scss" scoped>

</style>
